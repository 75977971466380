


.comments-title {
    text-align: center;
    padding-top: 6rem;
}


.comments-title span:before {
    content: "";
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0;
    height: 3px;
    border-radius: 2px;
    background: linear-gradient(130deg,#ff7a18,#af002d 41.07%,#319197 76.05%);
}

