/* .slick-slide img {
  width: 19.3125rem;
  height: 23.8125rem;
  object-fit: cover;
  margin: 0 20px 0;
}
.samecard .slick-prev {
  left: 0;
}
.samecard .slick-next {
  right: 5px;
}
.samecard .slick-slide a {
  display: grid;
}
.slick-slide {
  width: 19.3125rem !important;
}

.slick-slide .slick-cloned {
  width: 20rem !important;
}
 */
.slick-track {
  display: flex !important;
}

