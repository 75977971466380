@media screen and (max-width: 1300px) {
  #root {
    overflow-x: hidden;
  }
}
a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Origin Tech Demo";
  list-style: none;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.MuiPaper-root {
  border-radius: 0 !important;
}

a:hover {
  text-decoration: underline;
}
