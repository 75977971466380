.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    transform-style: preserve-3d;
    animation: animate 5s ease-in-out infinite alternate;

    span {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.5) 90%,
        transparent
      );
      text-transform: uppercase;
      line-height: 0.76em;
      color: #fff;
      font-size: 3.5em;
      white-space: nowrap;
      font-weight: bold;
      padding: 0px 10px;
      transform-style: preserve-3d;
      text-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
      transform: translate(-50%, -50%) rotateX(calc(var(--i) * 22.5deg))
        translateZ(109px);

      i {
        font-style: initial;

        &:nth-child(1) {
          color: #5c5fc4;
        }

        &:nth-child(2) {
          color: #c4c15c;
        }
      }
    }
  }
}

@keyframes animate {
  0% {
    transform: perspective(117px) rotateX(360deg) rotate(5deg);
  }

  100% {
    transform: perspective(500px) rotateX(10deg) rotate(15deg);
  }
}

.reveal {
  position: relative;
  transform: translateY(130px);
  opacity: 0;
  transition: 0.5s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.zoomin {
  transition: 0.5s all ease-in-out;
}

.zoomin:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 500px) {
  .zoomin {
    transition: 0.5s all ease-in-out;
  }
}

/* .neonText {
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #33ff00,
    0 0 82px #33ff00, 0 0 92px #33ff00, 0 0 102px #222, 0 0 151px #222;
  animation: pulsate 1.5s infinite alternate;
}

@keyframes pulsate {
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #222, 0 0 40px #33ff00,
      0 0 80px #33ff00, 0 0 90px #33ff00, 0 0 100px #222, 0 0 150px #222;
  }

  0% {
    text-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #222, 0 0 10px #bc13fe,
      0 0 45px #bc13fe, 0 0 55px #bc13fe, 0 0 70px #222, 0 0 80px #222;
  }
} */

.backdrop {
  background-color: rgba(0, 0, 0, 0.6);
}
.backdrop-1 {
  background-color: rgba(0, 0, 0, 0.5);
}
.end-0 {
  right: 0 !important;
}
.start-0 {
  left: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}
.top-0 {
  top: 0 !important;
}
.position-absolute {
  position: absolute !important;
}

/* 
.ray {
  clip-path:polygon(0% 45%, 100% 0%, 100% 100%, 0% 55%);
  transition: 4s;
transform: translateY(-50%) translateX(-50%) rotate(var(--angle)) translateY(50%) translateX(50%) translateY(-50%); 
  position: absolute;
  top: -30px;
  left: 50%;
  width: 500px;
  height: 100px;
  background: linear-gradient(0.25turn,rgba(255,255,0,0.7), rgba(255,255,100,0));
}


.light1 {
  animation-duration: 10s;
  animation-name: wave;
  animation-iteration-count: infinite;
}

.light2 {
  animation-duration: 7s;
  animation-name: wave;
  animation-iteration-count: infinite;
}


@keyframes wave {
  0% {
    --angle: 90deg;
  }
  
  50% {
    --angle: 110deg;
  }
  100% {
    --angle: 60deg;
  }
} */

