body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Origin Tech Demo";
  src: url("./fonts/OriginTechpersonaluse.otf") format("otf"),
    url("./fonts/OriginTechpersonaluse.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html {
  font-size: 16px;
  overflow-x: hidden;
}

@media screen and (max-width: 500px) {
  html {
    font-size: 10px;
  }
}
