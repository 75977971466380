.section-heading {
  font-family: "Origin Tech Demo";
  text-align: center;
  font-size: 64px;
  color: #10996d;
  margin: 0 0 70px;
}

.container {
  display: flex;
  justify-content: center;
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
}

.profile {
  position: relative;
  transition: all 0.3s;
}

.profile:hover {
  transform: translateY(25px);
}

.profile img {
  max-width: 200px;
  border-radius: 50%;
  border: 5px solid #f7f7f7;
  filter: drop-shadow(-20px 0 10px rgba(0, 0, 0, 0.1));
  cursor: pointer;
}

.profile:not(:first-child) img {
  margin-left: -20px;
}

.profile .name {
  position: absolute;
  background-color: #10996d;
  color: #fff;
  font-family: "Bebas Neue", cursive;
  padding: 15px 30px;
  border-radius: 100px;
  bottom: -80px;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50px);
  letter-spacing: 1px;
  font-size: 20px;
  opacity: 0;
  transition: all 0.3s;
}

.profile .name::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-color: #10996d;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.profile img:hover + .name {
  opacity: 1;
  transform: translateX(-50%);
  box-shadow: 0 10px 20px rgba(86, 86, 198, 0.3);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .profile {
    margin-bottom: 20px;
    width: calc(50% - 10px);
  }

  .profile img {
    max-width: 150px;
  }
}

@media screen and (max-width: 480px) {
  .profile {
    width: 100%;
  }

  .profile img {
    max-width: 80px;
  }
}
