.services {
    position: relative;
    height: 500px;
    margin-top: 60px;
}

.wheel_container {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    height: 1000px;
    width: 80%;
}

@media (max-width: 500px) {
    .wheel_container {
        width: 250px;
    }
}

.cardAnim {
    animation: cardsComeIn 1.5s cubic-bezier(1, 0, 0.1, 1);
    animation-fill-mode: backwards;
}

@keyframes cardsComeIn {
    0% {
        position: fixed;
        transform: translateX(-50%);
        top: -420px;
        left: 200px;
    }
}