.startup-screen {
    background-color: #f7f7f7;
    text-align: center;
    padding: 20px;
  }
  
  .heading {
    font-size: 24px;
    color: black;
  }
  
  .loading {
    font-size: 16px;
    color: gray;
  }
  
.container-wheel {
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-wheel .box {
  transform-style: preserve-3d;
  animation: animate 7s ease-in-out infinite alternate;
}
.container-wheel .box span {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.5) 90%,
    transparent
  );
  text-transform: uppercase;
  line-height: 0.76em;
  position: absolute;
  color: #fff;
  font-size: 3.5em;
  white-space: nowrap;
  font-weight: bold;
  padding: 0px 10px;
  transform-style: preserve-3d;
  text-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%) rotateX(calc(var(--i) * 22.5deg))
    translateZ(109px);
}
.container-wheel .box span i {
  font-style: initial;
}
.container-wheel .box span i:nth-child(1) {
  color: #5c5fc4;
}
.container-wheel .box span i:nth-child(2) {
  color: #c4c15c;
}

@keyframes animate {
  0% {
    transform: perspective(500px) rotateX(0deg) rotate(5deg);
  }
  100% {
    transform: perspective(50px) rotateX(360deg) rotate(5deg);
  }
}

